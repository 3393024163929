import {useState} from "react"

export const useCheckpasscode = ()=>{
    const Link = process.env.REACT_APP_LINK || "https://new-xp-back.onrender.com"
    const [incorect,setIncorect] = useState("")
    const [error , setError] = useState("")
    
    const checkpasscode = async(passcode)=>{
        const content = {passcode}
        try{

            const res = await fetch(Link + "/user/checkpasscode",{
                method: "POST",
                headers: { "Content-type": "application/json" },
                credentials: 'include', // For cookies if required
                body: JSON.stringify(content)
            })
            if(!res.ok){
                console.log("not ok")
            }
            if(res.ok){
                const data = await res.json()
                sessionStorage.setItem("passcode",passcode)
                console.log(data)
            }
        }
        catch(error){
            setError(error)
            console.log(error)
            setIncorect(error)
        }
    }
    return {incorect,error,checkpasscode}
}

export const Welcominemail = ()=>{
    const Link = process.env.REACT_APP_LINK || "https://new-xp-back.onrender.com"

    const [errorWelcomin , setError] = useState("")

    const Welcome = async(passcode ,email)=>{
        const content = {passcode ,email}
        try{
            const res = await fetch(Link + "/user/welcominentry",
            {
                method:"POST",
                credentials: 'include',   // Necessary to include cookies
                headers:{"Content-type":"application/json"},
                body:JSON.stringify(content)
            })
            if(!res.ok){
                console.log("not ok")
            }
            const data = await res.json()
            console.log(data)

        }
        catch(error)
        {
            setError(error)
            console.log(error)
        }
    }
    return{errorWelcomin ,Welcome}
}
